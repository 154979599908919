import React, { useCallback, useEffect, useState } from 'react';
import Input from 'src/components/Input/Input';
import { useAddPopup } from 'src/state/application/hooks';
import './AudioBook.css';
import { useDeleteAudioBook, useGetAudioBookBySeries, usePostAudioBook, usePutAudioBook } from 'src/api/audioBookApi';
import { AudioBookType } from 'src/api/models';
import AudioBookDetail from '../AudioBookDetail/AudioBookDetail';

interface IAudioBook {
  audioBookSeriesId: number;
} 

const AudioBook: React.FC<IAudioBook> = (props) => {
  const { audioBookSeriesId } = props;
  //Function
  const getAudioBookBySeries = useGetAudioBookBySeries();
  const postAudioBook = usePostAudioBook();
  const putAudioBook = usePutAudioBook();
  const deleteAudioBook = useDeleteAudioBook();
  const addPopup = useAddPopup();
  //State

  const [audioBooks, setAudioBooks] = useState<AudioBookType[]>([]);

  useEffect(() => {
    if (audioBookSeriesId)
        getAudioBookBySeries(audioBookSeriesId).then(( books) => {
          setAudioBooks(books);
        })
        .catch((error) =>
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          }),
        );
  }, [addPopup, audioBookSeriesId]);


  const onSave = (book: AudioBookType, value: AudioBookType) => {
    const api = value.audioBookId? putAudioBook : postAudioBook;
    api(value).then((r) => {
      book = r;
      setAudioBooks([...audioBooks]);
    })
    .catch((error) =>
      addPopup({
        error: {
          message: error.errorMessage,
          title: 'Đã có lỗi xảy ra!',
        },
      }),
    );
  }
  
  const onDelete = (book: AudioBookType) => {
    if(book.audioBookId) {
      deleteAudioBook(book.audioBookId).then(r => {
        const nBooks = audioBooks.filter(b => b != book);
        setAudioBooks(nBooks);
      })
      .catch((error) =>
        addPopup({
          error: {
            message: error.errorMessage,
            title: 'Đã có lỗi xảy ra!',
          },
        }),
      );
    } else {
      const nBooks = audioBooks.filter(b => b != book);
      setAudioBooks(nBooks);
    }
  }

  return (
    <div className="audio-book-container">
      {
        audioBooks.map((book, index) => {
          return <AudioBookDetail key={`audiobook${index}`} audioBook={book} onSave={(value) => onSave(book, value)} onDelete={() => onDelete(book)}/>
        })
      }
      <i className="fas fa-plus-circle" onClick={() => setAudioBooks([...audioBooks, {
        audioBookSeriesId: audioBookSeriesId,
        audioBookTitle: ''
      }])}></i>
    </div>
  );
};

export default AudioBook;
