import React, { useCallback, useEffect, useState } from 'react';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import Input from 'src/components/Input/Input';
import { useAddPopup } from 'src/state/application/hooks';
import './AudioBookSeriesDetail.css';
import { useGetAudioBookSeriesById, usePostAudioBookSeries, usePutAudioBookSeries } from 'src/api/audioBookApi';
import { AudioBookSeriesType, AudioBookType, ImageType } from 'src/api/models';
import AudioBook from '../AudioBook/AudioBook';
import Avatar from 'src/components/Avatar';

interface IAudioBookSeriesDetail {
  audioBookSeriesId: number;
  isDisable: boolean;
  postProcess?: (...args: any[]) => void;
}

const AudioBookSeriesDetail: React.FC<IAudioBookSeriesDetail> = (props) => {
  //Function
  const putAudioBookSeries = usePutAudioBookSeries();
  const getAudioBookSeries = useGetAudioBookSeriesById();
  const addPopup = useAddPopup();
  const postAudioBookSeries = usePostAudioBookSeries();
  
  //State
  const [audioBookSeriesId, setAudioBookSeriesId] = useState<number>(props.audioBookSeriesId);
  const [isDisable, setDisable] = useState<boolean>(props.isDisable);

  const [audioBookSeriesTitle, setAudioBookSeriesTitle] = useState<string>();
  const [image, setImage] = useState<string>();
  const [imageUrl, setImageUrl] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [displayOrder, setDisplayOrder] = useState<number>();

  useEffect(() => {
    if (audioBookSeriesId)
        getAudioBookSeries(audioBookSeriesId).then((series) => {
          setAudioBookSeriesTitle(series.audioBookSeriesTitle);
          setImage(series.image);
          setDisplayOrder(series.displayOrder);
          setDescription(series.description);
        })
        .catch((error) =>
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          }),
        );
  }, [addPopup, getAudioBookSeries, audioBookSeriesId, isDisable]);

  const onSave = useCallback(() => {
    const body: AudioBookSeriesType = {
      audioBookSeriesId,
      audioBookSeriesTitle,
      image,
      displayOrder,
      description
    }
    const api = !audioBookSeriesId? postAudioBookSeries : putAudioBookSeries;
    api(body).then((res) => {
      addPopup({
        txn: {
          success: true,
          summary: !audioBookSeriesId ? 'Thêm thành công bài giảng!' : 'Sửa thành công bài giảng',
        },
      });
      setAudioBookSeriesId(res.audioBookSeriesId);
      setDisable(true);
      if (props.postProcess) props.postProcess(res);
    })
    .catch((error) => {
      addPopup({
        error: {
          message: error.errorMessage,
          title: 'Đã có lỗi xảy ra!',
        },
      });
    });
  }, [
    audioBookSeriesId,
    postAudioBookSeries,
    audioBookSeriesTitle,
    image,
    displayOrder,
    description,
    putAudioBookSeries,
    addPopup,
    props,
  ]);

  return (
    <div className="audio-book-series-detail-container">
      <div className='audio-book-series-content'>
        <div className='audio-book-series-detail-col'>
          <Avatar thumbAvatar={image} avatar={image} onChoose={(file: ImageType) => {
                setImage(file.fileUrl)
              }} disable={isDisable} />
        </div>
        <div className='audio-book-series-detail-col'>
          <div className="audio-book-series-detail-row">
            <Input
              width='300px'
              title="Tên bài giảng"
              require={true}
              value={audioBookSeriesTitle}
              onChange={setAudioBookSeriesTitle}
              disabled={isDisable}
            />
          </div>
          <div className="audio-book-series-detail-row">
            <Input
             width='300px'
              title="Thứ tự"
              require={true}
              disabled={isDisable}
              value={displayOrder}
              onChange={setDisplayOrder}
            />
          </div>
        </div>
        <div className='audio-book-series-detail-col'>
          <div className="audio-book-series-detail-row">
            <Input
             width='300px'
              title="Mô tả"
              disabled={isDisable}
              value={description}
              onChange={setDescription}
              row={4}
            />
          </div>
        </div>
           
      </div>
      {audioBookSeriesId > 0? <AudioBook audioBookSeriesId={audioBookSeriesId} /> : null}
      {isDisable ? null : (
        <ButtonComponent
          icon="save"
          title={!audioBookSeriesTitle ? 'THÊM' : 'LƯU'}
          onClick={onSave}
        />
      )}
    </div>
  );
};

export default AudioBookSeriesDetail;
