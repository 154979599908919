import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { PageData, DestinationType } from './models';

/***********************************/
export const useGetDestination = () => {
  const fetch = useFetch();
  return useCallback(() => {
    return fetch({
      url: `mccore/rest-api/destination`,
      method: 'get',
    }) as Promise<PageData<DestinationType>>;
  }, [fetch]);
};

export const usePostDestination = () => {
  const fetch = useFetch();
  return useCallback(
    (data: DestinationType) => {
      return fetch({
        url: 'mccore/rest-api/destination',
        method: 'post',
        data: data,
      }) as Promise<DestinationType>;
    },
    [fetch],
  );
};

export const useGetDestinationById = () => {
  const fetch = useFetch();
  return useCallback(
    (destinationId: number) => {
      return fetch({
        url: `mccore/rest-api/destination/${destinationId}`,
        method: 'get',
      }) as Promise<DestinationType>;
    },
    [fetch],
  );
};

export const usePutDestination = () => {
  const fetch = useFetch();
  return useCallback(
    (data: DestinationType) => {
      return fetch({
        url: `mccore/rest-api/destination`,
        method: 'put',
        data: data,
      }) as Promise<DestinationType>;
    },
    [fetch],
  );
};

export const useDeleteDestination = () => {
  const fetch = useFetch();
  return useCallback(
    (destinationId: number) => {
      return fetch({
        url: `mccore/rest-api/destination/${destinationId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};
