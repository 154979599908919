/************************************/
/********                    ********/
/******   Inventory          ********/
/******   Writen by LuanPT   ** ****/
/********                   ********/

import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { InventoryType, PageData } from './models';

/***********************************/

export const useGetInventories = () => {
  const fetch = useFetch();
  return useCallback(
    (page: number, size: number, productId: number, status: number) => {
      return fetch({
        url: `mccore/rest-api/inventory?page=${page??0}&size=${size??0}${productId?`&productId=${productId}`:''}${status?`&status=${status}`:''}`,
        method: 'get',
      }) as Promise<PageData<InventoryType>>;
    },
    [fetch],
  );
};

export const useGetInventory = () => {
  const fetch = useFetch();
  return useCallback(
    (inventoryId: number) => {
      return fetch({
        url: `mccore/rest-api/inventory/${inventoryId}`,
        method: 'get',
      }) as Promise<InventoryType>;
    },
    [fetch],
  );
};

export const usePostInventory = () => {
  const fetch = useFetch();
  return useCallback(
    (data: InventoryType) => {
      return fetch({
        url: `mccore/rest-api/inventory`,
        method: 'post',
        data: data,
      }) as Promise<InventoryType>;
    },
    [fetch],
  );
};

export const useDeleteInventory = () => {
  const fetch = useFetch();
  return useCallback(
    (inventoryId: number) => {
      return fetch({
        url: `mccore/rest-api/inventory/${inventoryId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

