import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { AudioBookSeriesType, AudioBookType, AudioChapterType, ImageType, PageData, } from './models';

/***********************************/
export const useGetListAudioBookSeries = () => {
  const fetch = useFetch();
  return useCallback(() => {
    return fetch({
      url: `mccore/rest-api/audio-book-series`,
      method: 'get',
    }) as Promise<PageData<AudioBookSeriesType>>;
  }, [fetch]);
};

export const usePostAudioBookSeries = () => {
  const fetch = useFetch();
  return useCallback(
    (data: AudioBookSeriesType) => {
      return fetch({
        url: 'mccore/rest-api/audio-book-series',
        method: 'post',
        data: data,
      }) as Promise<AudioBookSeriesType>;
    },
    [fetch],
  );
};

export const useGetAudioBookSeriesById = () => {
  const fetch = useFetch();
  return useCallback(
    (audioBookSeriesId: number) => {
      return fetch({
        url: `mccore/rest-api/audio-book-series/${audioBookSeriesId}`,
        method: 'get',
      }) as Promise<AudioBookSeriesType>;
    },
    [fetch],
  );
};

export const useGetAudioBookBySeries = () => {
  const fetch = useFetch();
  return useCallback(
    (audioBookSeriesId: number) => {
      return fetch({
        url: `mccore/rest-api/audio-book-series/${audioBookSeriesId}/audio-book`,
        method: 'get',
      }) as Promise<AudioBookType[]>;
    },
    [fetch],
  );
};

export const useGetAudioChapter = () => {
  const fetch = useFetch();
  return useCallback(
    (audioBookId: number) => {
      return fetch({
        url: `mccore/rest-api/audio-book-series/audio-book/${audioBookId}/audio-chapter`,
        method: 'get',
      }) as Promise<PageData<AudioChapterType>>;
    },
    [fetch],
  );
};

export const usePutAudioBookSeries = () => {
  const fetch = useFetch();
  return useCallback(
    (data: AudioBookSeriesType) => {
      return fetch({
        url: `mccore/rest-api/audio-book-series`,
        method: 'put',
        data: data,
      }) as Promise<AudioBookSeriesType>;
    },
    [fetch],
  );
};

export const useDeleteAudioBookSeries = () => {
  const fetch = useFetch();
  return useCallback(
    (id: number) => {
      return fetch({
        url: `mccore/rest-api/audio-book-series/${id}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const usePostAudioBook = () => {
  const fetch = useFetch();
  return useCallback(
    (data: AudioBookType) => {
      return fetch({
        url: `mccore/rest-api/audio-book-series/audio-book`,
        method: 'put',
        data: data,
      }) as Promise<AudioBookType>;
    },
    [fetch],
  );
};

export const usePutAudioBook = () => {
  const fetch = useFetch();
  return useCallback(
    (data: AudioBookType) => {
      return fetch({
        url: `mccore/rest-api/audio-book-series/audio-book`,
        method: 'put',
        data: data,
      }) as Promise<AudioBookType>;
    },
    [fetch],
  );
};

export const useDeleteAudioBook = () => {
  const fetch = useFetch();
  return useCallback(
    (id: number) => {
      return fetch({
        url: `mccore/rest-api/audio-book-series/audio-book/${id}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const usePostAudioChapter = () => {
  const fetch = useFetch();
  return useCallback(
    (data: AudioChapterType) => {
      return fetch({
        url: `mccore/rest-api/audio-book-series/audio-chapter`,
        method: 'put',
        data: data,
      }) as Promise<AudioChapterType>;
    },
    [fetch],
  );
};

export const usePutAudioChapter = () => {
  const fetch = useFetch();
  return useCallback(
    (data: AudioChapterType) => {
      return fetch({
        url: `mccore/rest-api/audio-book-series/audio-chapter`,
        method: 'put',
        data: data,
      }) as Promise<AudioChapterType>;
    },
    [fetch],
  );
};

export const useDeleteAudioChapter = () => {
  const fetch = useFetch();
  return useCallback(
    (id: number) => {
      return fetch({
        url: `mccore/rest-api/audio-book-series/audio-chapter/${id}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};


export const useGetImages = () => {
  const fetch = useFetch();
  return useCallback(() => {
    return fetch({
      url: `mccore/rest-api/audio-book-series/image`,
      method: 'get',
    }) as Promise<ImageType[]>;
  }, [fetch]);
};

export const useAddImage = () => {
  const fetch = useFetch(false, true);
  return useCallback(
    (data: any) => {
      return fetch({
        url: `mccore/rest-api/audio-book-series/image`,
        method: 'post',
        data: data,
      }) as Promise<ImageType>;
    },
    [fetch],
  );
};

export const useDeleteImage = () => {
  const fetch = useFetch();
  return useCallback(
    (fileId: number) => {
      return fetch({
        url: `mccore/rest-api/audio-book-series/image/${fileId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};
