import './AudioBookSeries.css';
import {
  useAddPopup,
} from 'src/state/application/hooks';
import { EnumAction, EnumDataType, EventButton, AudioBookSeriesType } from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import React from 'react';
import useModal from 'src/hooks/useModal';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import { useAddImage, useDeleteAudioBookSeries, useDeleteImage, useGetImages, useGetListAudioBookSeries } from 'src/api/audioBookApi';
import AudioBookSeriesDetail from './AudioBookSeriesDetail/AudioBookSeriesDetail';
import UploadImageLib from 'src/components/UploadImageLib';

//BookSeries
const AudioBookSeries: React.FC = () => {
  const detailModal = useModal(AudioBookSeriesDetail);
  const [reloadFlag, setReloadFlag] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const getImages = useGetImages();
  const deleteImage = useDeleteImage();
  const addImage = useAddImage();
  const imageModal = useModal(UploadImageLib);

  const header = {
    header: [
      {
        code: 'audioBookSeriesId',
        dataType: EnumDataType.BigInt,
        isOptions: false,
        title: 'ID',
      },
      {
        code: 'audioBookSeriesTitle',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tên bài giảng',
      },
      {
        code: 'image',
        dataType: EnumDataType.Image,
        isOptions: false,
        title: 'Hình ảnh',
      },
      {
        code: 'description',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mô tả',
      },
      {
        code: 'displayOrder',
        dataType: EnumDataType.Int,
        isOptions: false,
        title: 'Thứ tự',
        cellCss: {
          textAlign: 'center',
        },
      },
    ] as TableColumnType[],
  };

  //Function
  const addPopup = useAddPopup();
  const deleteBookSeries = useDeleteAudioBookSeries();
  const getBookSeries = useGetListAudioBookSeries();
  const confirmModal = useModal(ConfirmModal);

  const onView = (item: AudioBookSeriesType) =>
    detailModal.handlePresent(
      {
        audioBookSeriesId: item.audioBookSeriesId,
        isDisable: true,
      },
      'CHI TIẾT',
    );
  const menuContext = (item: AudioBookSeriesType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.View,
      action: () => onView(item),
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () =>
        detailModal.handlePresent(
          {
            audioBookSeriesId: item.audioBookSeriesId,
            isDisable: false,
            postProcess: (data: AudioBookSeriesType) => {
              detailModal.handleDismiss();
              setReloadFlag(!reloadFlag);
              onView(data);
            },
          },
          'THAY ĐỔI',
        ),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Delete,
      action: () => {
        const onConfirm = () => {
          deleteBookSeries(item.audioBookSeriesId)
            .then(() => {
              addPopup({
                txn: {
                  success: true,
                  summary: 'Xóa bài giảng thành công',
                },
              });
            })
            .catch((error) => {
              addPopup({
                error: {
                  message: error.errorMessage,
                  title: 'Đã có lỗi xảy ra!',
                },
              });
            })
            .finally(() => setReloadFlag(!reloadFlag));
        };
        const listButton = [
          {
            name: 'Xác nhận',
            icon: 'check',
            actionType: EnumAction.Confirm,
            action: onConfirm,
            buttonClass: 'info',
            align: 'center',
          },
          {
            name: 'Hủy',
            icon: 'clear',
            actionType: EnumAction.Cancel,
            buttonClass: 'cancel',
            align: 'center',
          },
        ];
        confirmModal.handlePresent(
          {
            question: 'Bạn có chắc muốn xóa bài giảng này?',
            listActionButton: listButton,
            postProcess: confirmModal.handleDismiss,
          },
          'XÓA BÀI GIẢNG',
        );
      },
      buttonClass: 'info',
      align: '',
    },
  ];

  const [data, setData] = useState([]);
  const [count, setCount] = useState<number>();

  useEffect(() => {
    console.log('Refresh');
    getBookSeries()
      .then((data) => {
        setData(data.items);
        setCount(data.count);
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [getBookSeries, page, size, reloadFlag]);


  const listButton: EventButton[] = [
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.Add,
      buttonClass: 'info300 sim-order-tool-btn',
      action: () =>
        detailModal.handlePresent(
          {
            isDisable: false,
            postProcess: (data: AudioBookSeriesType) => {
              detailModal.handleDismiss();
              setReloadFlag(!reloadFlag);
              onView(data);
            },
          },
          'THÊM MỚI',
        ),
      align: 'center',
    },
    {
      name: 'D.sách ảnh',
      icon: 'image',
      actionType: EnumAction.Add,
      buttonClass: 'info200 static-destination-tool-btn cloud_upload-btn',
      action: () => {
        imageModal.handlePresent({
          getApi: getImages,
          deleteApi: deleteImage,
          addApi: addImage
        }, 'DANH SÁCH ẢNH');
      },
      align: 'center',
    },
  ];
  //End of toolbar

  return (
    <>
      <ToolBar
        toolbarName={'DANH SÁCH BÀI GIẢNG'}
        listRightButton={listButton}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        page={page}
        size={size}
        isPaging={true}
        count={count}
        onChangePage={setPage}
        onChangeSize={setSize}
      />
      <Table
        display={header}
        isInput={false}
        data={data}
        menuContext={menuContext}
        onDoubleClick={(item) => onView(item)}
      />
    </>
  );
};

export default AudioBookSeries;
