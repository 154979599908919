import React, { useCallback, useEffect, useState } from 'react';
import Input from 'src/components/Input/Input';
import { useAddPopup } from 'src/state/application/hooks';
import './AudioBookDetail.css';
import { AudioBookType, AudioChapterType, ImageType } from 'src/api/models';
import Avatar from 'src/components/Avatar';
import { useDeleteAudioChapter, useGetAudioChapter, usePostAudioChapter, usePutAudioChapter } from 'src/api/audioBookApi';
import AudioChapter from '../AudioChapter/AudioChapter';

interface IAudioBookDetail {
  audioBook?: AudioBookType;
  onSave?: (value: AudioBookType) => void;
  onDelete?: () => void;
} 

const AudioBookDetail: React.FC<IAudioBookDetail> = (props) => {
  const { audioBook, onSave, onDelete } = props;
  const [isDisable, setDisable] = useState<boolean>(audioBook.audioBookId > 0);

  const addPopup = useAddPopup();

  const [audioBookTitle, setAudioBookTitle] = useState<string>(audioBook?.audioBookTitle);
  const [image, setImage] = useState<string>(audioBook?.image);
  const [description, setDescription] = useState<string>(audioBook?.description);
  const [displayOrder, setDisplayOrder] = useState<number>(audioBook?.displayOrder);
  const [chapters, setChapters] = useState<AudioChapterType[]>([]);

  const getAudioChapter = useGetAudioChapter();
  const postAudioChapter = usePostAudioChapter();
  const putAudioChapter = usePutAudioChapter();
  const deleteAudioChapter = useDeleteAudioChapter();


  useEffect(() => {
    if(audioBook.audioBookId) getAudioChapter(audioBook.audioBookId).then(res => {
      setChapters(res.items)
    }).catch((error) =>
      addPopup({
        error: {
          message: error.errorMessage,
          title: 'Đã có lỗi xảy ra!',
        },
      }),
    );
  }, [])
 
  const onClean = () => {
    setAudioBookTitle(audioBook?.audioBookTitle);
    setImage(audioBook?.image);
    setDescription(audioBook?.description);
    setDisplayOrder(audioBook?.displayOrder);
    setDisable(true);
  }

  
  const onSaveChapter = (chapter: AudioChapterType, value: AudioChapterType) => {
    const api = value.audioChapterId? putAudioChapter: postAudioChapter;
    api(value).then((r) => {
      chapter= r;
      setChapters([...chapters].sort());
    })
    .catch((error) =>
      addPopup({
        error: {
          message: error.errorMessage,
          title: 'Đã có lỗi xảy ra!',
        },
      }),
    );
  }
  
  const onDeleteChapter = (chapter: AudioChapterType) => {
    if(chapter.audioChapterId) {
      deleteAudioChapter(chapter.audioChapterId).then(r => {
        const nChapters = chapters.filter(b => b != chapter);
        setChapters(nChapters);
      })
      .catch((error) =>
        addPopup({
          error: {
            message: error.errorMessage,
            title: 'Đã có lỗi xảy ra!',
          },
        }),
      );
    } else {
      const nChapters = chapters.filter(b => b != chapter);
      setChapters(nChapters);
    }
  }

  return (
    <div className="audio-book-detail-container">
      <div className='audio-book-detail-action'>
        {isDisable? <i className="fas fa-edit" onClick={() => setDisable(false)}></i> :
          <><i className="fas fa-save" onClick={() => {
            const value: AudioBookType = {
              audioBookTitle: audioBookTitle,
              image: image,
              description: description,
              displayOrder: displayOrder,
              audioBookId: audioBook.audioBookId,
              audioBookSeriesId: audioBook.audioBookSeriesId
            }
           
            onSave(value);
            setDisable(true)
          }}></i>
          <i className="fas fa-times" onClick={onClean}></i></> }
       
        <i className="fas fa-trash" onClick={onDelete}></i>
      </div> 
      <Avatar thumbAvatar={image} avatar={image} onChoose={(file: ImageType) => {
        setImage(file.fileUrl)
      }} disable={isDisable} />
      <Input
        width='100%'
        title='Tiêu đề'
        require={true}
        value={audioBookTitle}
        onChange={setAudioBookTitle}
        disabled={isDisable}
      />
      <Input
        width='100%'
        title='Mô tả'
        require={true}
        value={description}
        disabled={isDisable}
        onChange={setDescription}
      />
      <Input
        width='100%'
        title='Thứ tự'
        require={true}
        value={displayOrder}
        disabled={isDisable}
        onChange={setDisplayOrder}
      />
      <hr/>
      <p>Danh sách video</p>
      {
        chapters.map((chapter, index) => {
          return <AudioChapter key={`audiochapter${index}`} audioChapter={chapter} onSave={(value) => onSaveChapter(chapter, value)} onDelete={() => onDeleteChapter(chapter)}/>
        })
      }
      <i className="fas fa-plus-circle" onClick={() => setChapters([...chapters, {
        audioChapterTitle: '',
        audioBookId: audioBook.audioBookId,
        url: ''
      }])}></i>
    </div>
  );
};

export default AudioBookDetail;
