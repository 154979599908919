import React, { useCallback, useEffect, useState } from 'react';
import Input from 'src/components/Input/Input';
import './AudioChapter.css';
import { AudioChapterType } from 'src/api/models';

interface IAudioChapter {
  audioChapter?: AudioChapterType;
  onSave?: (value: AudioChapterType) => void;
  onDelete?: () => void;
} 

const AudioChapter: React.FC<IAudioChapter> = (props) => {
  const { audioChapter, onSave, onDelete } = props;
  const [isDisable, setDisable] = useState<boolean>(audioChapter.audioChapterId > 0);

  const [audioChapterTitle, setAudioChapterTitle] = useState<string>(audioChapter?.audioChapterTitle);
  const [url, setUrl] = useState<string>(audioChapter?.url);
  const [displayOrder, setDisplayOrder] = useState<number>(audioChapter?.displayOrder);

 
  const onClean = () => {
    setAudioChapterTitle(audioChapter?.audioChapterTitle);
    setUrl(audioChapter?.url);
    setDisplayOrder(audioChapter?.displayOrder);
    setDisable(true);
  }

  return (
    <div className="audio-chapter-container">
      <div className='audio-chapter-action'>
        {isDisable? <i className="fas fa-edit" onClick={() => setDisable(false)}></i> :
          <><i className="fas fa-save" onClick={() => {
            const value: AudioChapterType = {
              audioChapterTitle: audioChapterTitle,
              url: url,
              displayOrder: displayOrder,
              audioBookId: audioChapter.audioBookId,
              audioChapterId: audioChapter.audioChapterId
            }
           
            onSave(value);
            setDisable(true)
          }}></i>
          <i className="fas fa-times" onClick={onClean}></i></> }
       
        <i className="fas fa-trash" onClick={onDelete}></i>
      </div> 
      <Input
        width='100%'
        title='Tiêu đề'
        require={true}
        value={audioChapterTitle}
        onChange={setAudioChapterTitle}
        disabled={isDisable}
      />
      <Input
        width='100%'
        title='Đường dẫn'
        require={true}
        value={url}
        disabled={isDisable}
        onChange={setUrl}
      />
      <Input
        width='100%'
        title='Thứ tự'
        require={true}
        value={displayOrder}
        disabled={isDisable}
        onChange={setDisplayOrder}
      />
      
    </div>
  );
};

export default AudioChapter;
