import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { LibraryType, ImageType } from './models';

/***********************************/
export const useGetLibraries = () => {
  const fetch = useFetch();
  return useCallback(() => {
    return fetch({
      url: `mccore/rest-api/library`,
      method: 'get',
    }) as Promise<LibraryType[]>;
  }, [fetch]);
};

export const usePostLibrary = () => {
  const fetch = useFetch();
  return useCallback(
    (data: LibraryType) => {
      return fetch({
        url: 'mccore/rest-api/library',
        method: 'post',
        data: data,
      }) as Promise<LibraryType>;
    },
    [fetch],
  );
};

export const useGetLibraryById = () => {
  const fetch = useFetch();
  return useCallback(
    (libraryId: number) => {
      return fetch({
        url: `mccore/rest-api/library/${libraryId}`,
        method: 'get',
      }) as Promise<LibraryType>;
    },
    [fetch],
  );
};

export const usePutLibrary = () => {
  const fetch = useFetch();
  return useCallback(
    (data: LibraryType) => {
      return fetch({
        url: `mccore/rest-api/library`,
        method: 'put',
        data: data,
      }) as Promise<LibraryType>;
    },
    [fetch],
  );
};

export const useDeleteLibrary = () => {
  const fetch = useFetch();
  return useCallback(
    (libraryId: number) => {
      return fetch({
        url: `mccore/rest-api/library/${libraryId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const useUpdateLibraryAvatar = () => {
  const fetch = useFetch(false, true);
  return useCallback(
    (id: number, data: any) => {
      return fetch({
        url: `mccore/rest-api/library/avatar/${id}`,
        method: 'post',
        data: data,
      }) as Promise<ImageType>;
    },
    [fetch],
  );
};

export const useAddLibraryImage = () => {
  const fetch = useFetch(false, true);
  return useCallback(
    (id: number, data: any) => {
      return fetch({
        url: `mccore/rest-api/library/image/${id}`,
        method: 'post',
        data: data,
      }) as Promise<ImageType>;
    },
    [fetch],
  );
};

export const useDeleteLibraryImage = () => {
  const fetch = useFetch();
  return useCallback(
    (libraryId: number, fileId: number) => {
      return fetch({
        url: `mccore/rest-api/library/${libraryId}/image/${fileId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};
